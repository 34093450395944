import { createAsync } from '@solidjs/router';
import { AccessProductsCtx, getAccessProducts } from '../../../providers/card';
import { GolferContext } from './_providers/golfer';
import type { RouteSectionProps } from '@solidjs/router';

export default function AssociateAccess(props: RouteSectionProps) {
	const data = createAsync(async () => (await getAccessProducts({}))?.products, {
		deferStream: true,
	});
	return (
		<GolferContext>
			<AccessProductsCtx.Provider value={data}>{props.children}</AccessProductsCtx.Provider>
		</GolferContext>
	);
}
